$(function() {

    $('.js-map')
        .each(function() {
            var $a = $(this);

            $('.js-map-btn', $a).removeClass('active');
            // $('.js-map-body', $a).hide();
        })
        .on('click', '.js-map-btn', function() {
            var $a = $(this),
                $b = $a.parents('.js-map'),
                i = $('.js-map-btn', $b).index($a);

            $('.js-map-btn', $b).removeClass('active').eq(i).addClass('active');
            $('.js-map-body').hide().eq(i).show();

            $('html,body').css('overflow', 'hidden');
            $('.js-modal').stop().fadeIn(300);

        });

    var $scroll = $('.js-scroll');
    if($scroll.length)
        $scroll
            .perfectScrollbar({
                wheelPropagation:true
            });

    $('.js-modal')
        .hide()
        .css('opacity', 1)
        .on('click', function() {
            $(this).stop().fadeOut(300);
            $('html,body').css('overflow', 'auto');
        });

    $('.js-map-body')
        .on('click', function(e) {
            e.stopPropagation();
        });

    $('.js-close')
        .on('click', function() {
            $('.js-modal').stop().fadeOut(300);
            $('html,body').css('overflow', 'auto');

            return false;
        });

});